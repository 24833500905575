<template>
  <div>
    <PInlineMessage v-if="notice !== undefined" :severity="notice.severity">
      {{ notice.message }}
    </PInlineMessage>

    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :showClear="true"
      :inputId="props.column.fieldInfo.name"
      :modelValue="props.filter.value"
      :filter="false"
      :editable="false"
      :class="['w-full']"
      :inputClass="['form-select', 'sm:mt-2', 'sm:mr-2']"
      v-on="eventListeners"
    >
    </PDropdown>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from "#i18n";
  import { computed, shallowRef, watch } from "vue";
  import PDropdown, { DropdownChangeEvent } from "primevue/dropdown";
  import PInlineMessage from "primevue/inlinemessage";
  import { logger } from "~/service/logger/logger";
  import { NoticeMessage, NoticeSeverityEnum } from "~/service/notice/types";
  import { defineNoticeMessage } from "~/service/notice/notices";
  import { normalizeTranslationString } from "~/shared/lib/string";
  import { ColumnFilterProps, ColumnFilterEmits } from "../../lib/interfaces";

  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();

  const { t } = useI18n();

  const notice = shallowRef<NoticeMessage | undefined>(undefined);

  const options = computed(() => {
    if (!("choices" in props.column.fieldInfo.meta.options)) {
      return [];
    }

    return (
      props.column.fieldInfo.meta.options.choices?.map((choice) => ({
        value: choice.value,
        label: normalizeTranslationString(choice.text),
      })) ?? []
    );
  });

  watch(
    () => props.column.fieldInfo.meta?.options,
    (options) => {
      if (!("choices" in options)) {
        const errorLogMessage: string = `Not found required choices key in options. Returns array.`;
        const errorMessage: string = t("field_select_error_incorrect_choices");

        logger().error(
          {
            fieldName: props.column.fieldInfo.name,
          },
          errorLogMessage,
        );

        notice.value = defineNoticeMessage({
          severity: NoticeSeverityEnum.ERROR,
          message: errorMessage,
        });
      } else {
        notice.value = undefined;
      }
    },
  );

  const eventListeners = {
    change: (event: DropdownChangeEvent) => {
      emit("update:filter", {
        filterName: props.column.name,
        data: event.value,
        immediate: true,
      });
    },
  };
</script>

<style scoped></style>
